@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');


/*  ===== CSS Variables ===== */
:root {
  --theme-bg: white;
  --theme-color: #151515;
  --sample-bg: #f3faff;
  --sample-border: rgb(209, 231, 238);
  --sample-link: #1c1c1c;
  --sample-des: #125d92;
  --github-color: #181818;
  --auth-border: #0081b3;
  --nav-link: #191919;
  --nav-bggray-link: white;
  --nav-bggray-link-dark: #151515;
  --nav-link-hover: #0081b3;
  --bg-bg: rgb(0, 139, 194);
  --navbar-btn-bg: #f4f4f4;
  --navbar-btn-border: #56a2f9;
  --navbar-btn-color: #0081b3;
  --question-icon-color: #0081b3;
  --question-icon-border: #56a2f9;
  --settings-icon-color: #0081b3;
  --settings-icon-border: #56a2f9;
  --hamburger-color: #151515;
  --gosdk-shadow: rgb(96, 223, 255);
  --node-shadow: rgb(60, 228, 152);
  --python-shadow: rgb(187, 187, 0);
  --react-shadow: rgb(23, 228, 232);
  --web-shadow: rgb(210, 210, 3);
  --swift-shadow: rgb(255, 119, 0);
  --flutter-shadow: rgb(44, 195, 255);
  --jwt-bg: #f6f6f6;
  --jwt-border-color: rgb(226, 226, 226);
  --jwt-color1: #ba09e6;
  --jwt-color2: rgb(169, 76, 0);
  --logout-btn-bg: rgb(255, 241, 232);
  --logout-btn-color: red;
  --navbar-bg: rgb(240, 240, 240);
  --nav-bg-mobile: #0081b3;
  --example-bg: rgb(246, 251, 255);
  --error-border: #0081b3;
  --question-modal-bg: #ebebeb;
  --question-modal-border: #2b8eff;
  --question-color: #0a729c;
  --question-modal-default-color: #151515;
  --form-bg: rgb(223, 226, 228);
  --form-input-color: #181818;
  --questions-modal-color: #0075a3;
  --update-btn-bg: #0081b3;
  --update-btn-color: aliceblue;
  --pulse-bg: rgba(63, 158, 202, 0.9);
  --settings-label-color: #066c94;
  --download-btn-text: #e1f6ff;
  --select-container-bg: #e1f3fa;
  --download-des-bg: #eefbff;
  --select-container-list-bg: #fff;
  --select-container-text: #00658c;
  --select-down-arrow-bg: #0081b3;
  --select-down-arrow-color: aliceblue;
  --select-focus: #e1f7ff;
  --download-btn-bg: #0081b3;
  --sample-title-color: #0081b3;
}
[data-theme="dark"] {
  --theme-bg: #121217;
  --theme-color: #c6c6cb;
  --sample-bg: #141416;
  --sample-border: #353535;
  --sample-link: rgb(107, 236, 236);
  --sample-des: #c1bfbf;
  --github-color: #ededed;
  --auth-border: rgb(79, 79, 79);
  --nav-link: #f1f1f1;
  --nav-bggray-link: #f1f1f1;
  --nav-bggray-link-dark: #f1f1f1;
  --nav-link-hover: #76ffda;
  --nav-bg-mobile: #76ffda;
  --bg-bg: rgb(28, 28, 28);
  --navbar-btn-bg: #1c1c1c;
  --navbar-btn-border: #56f9aa;
  --navbar-btn-color: gold;
  --question-icon-color: gold;
  --question-icon-border: #56f9aa;
  --settings-icon-color: gold;
  --settings-icon-border: #56f9aa;
  --hamburger-color: #39e7a1;
  --gosdk-shadow: rgb(45, 143, 255);
  --node-shadow: rgb(25, 255, 128);
  --python-shadow: rgb(202, 202, 14);
  --react-shadow: rgb(0, 242, 255);
  --kotlin-shadow: rgb(106, 65, 255);
  --java-shadow: rgb(65, 132, 255);
  --web-shadow: rgb(181, 169, 1);
  --swift-shadow: rgb(231, 111, 6);
  --flutter-shadow: rgb(0, 171, 239);
  --jwt-bg: #171717;
  --jwt-border-color: rgb(78, 78, 78);
  --jwt-color1: #e57eff;
  --jwt-color2: rgb(255, 161, 84);
  --logout-btn-bg: rgb(33, 33, 33);
  --logout-btn-color: rgb(255, 59, 59);
  --navbar-bg: rgb(32, 32, 32);
  --example-bg: rgb(21, 21, 21);
  --error-border: rgb(107, 236, 236);
  --question-modal-bg: #191919;
  --question-modal-border: rgb(70, 70, 70);
  --question-color: rgb(23, 233, 208);
  --question-modal-default-color: #dadada;
  --form-bg: #232323;
  --form-input-color: rgb(228, 228, 228);
  --questions-modal-color: rgb(7, 223, 198);
  --update-btn-bg: #232323;
  --update-btn-color: rgb(7, 223, 198);
  --pulse-bg: rgb(7, 207, 184);
  --settings-label-color: rgb(228, 228, 228);
  --download-btn-text: rgb(26, 234, 220);
  --select-container-bg: #1c1f25;
  --download-des-bg: #16181c;
  --select-container-list-bg: #1d2026;
  --select-container-text: rgb(26, 234, 220);
  --select-down-arrow-bg: #262b31;
  --select-down-arrow-color: rgb(19, 221, 184);
  --select-focus: #15171b;
  --download-btn-bg: #1c1f25;
  --sample-title-color: #0ed8d2;
}

/*  ===== html + body ===== */
html, body {
  font-family: 'JetBrains Mono', monospace;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--theme-bg);
  color: var(--theme-color);
}


/*  ===== Globally acessible ===== */
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.fullscreen {
  min-height: 100vh;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.gradient {
  background-image: linear-gradient(84.37deg,#0081b3 2.3%,#0ff 34.46%,#6fef2d 67.18%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
h1, p {
  max-width: 90%;
}
h1 {
  margin: 0;
  font-size: 3.1rem;
  margin-bottom: 3vh;
}
p {
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 3vh;
}
.curved {
  border-radius: 100px;
  height: 5vh;
  padding: 0 4vh;
}
.title {
  color: #0081b3;
  font-size: 4rem;
  margin-top: 2vh;
}
.tagline {
  margin: 0;
  margin-bottom: 4vh;
}


/*  ===== Error ===== */
.error-title {
  color: var(--error-border);
}
.example {
  border: 2px solid var(--error-border);
  width: fit-content;
  border-radius: 100px;
  padding: 3vh 5vh;
  background-color: var(--example-bg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  word-break: break-all;
}
.copy-icon {
  margin-left: 1.8rem;
  height: 100%;
}
.error-txt {
  max-width: 70%;
  line-height: 1.8em;
}
.error-blue {
  color: var(--sample-link);
}


/*  ===== AuthFlow ===== */
.authflow {
  justify-content: center;
  width: 100%;
}
.download-des, .flow-shown {
  border: 1px solid var(--auth-border);
  border-radius: 14px;
  width: 80vh;
}
.flow-shown {  
  border: 1px solid var(--auth-border);
  border-radius: 14px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 0 4vh;
  width: 80vh;
}
.rounded-top {
  border-radius: 14px;
}
.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--logout-btn-color);
  border: none;
  width: 6vh;
  height: 6vh;
  background-color: var(--logout-btn-bg);
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1.5vh;
}
.jwt-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  align-items: stretch;
  width: 90vw;
  height: 100%;
}
.jwt-box {
  flex: 1;
  padding: 1vh;
  width: 45vw;
  align-items: stretch;
}
pre {
  align-items: stretch;
  text-align: left;
  overflow: auto;
  border: 1.5px solid var(--jwt-border-color);
  background-color: var(--jwt-bg);
  padding: 3vh;
  font-size: 0.95em;
  max-width: 100%;
  border-radius: 20px;
  font-family:  'Source Code Pro', monospace;
  height: 85vh;
}
.pre1 {
  color: var(--jwt-color1);
}
.pre2 {
  color: var(--jwt-color2);
}


/*  ===== Bottom Navbar ===== */
.bottom-nav {
  margin-top: 15vh;
  background: #121217
}
.bottom-title {
  margin: 0;
  font-size: 1.2rem;
  color: rgb(33, 33, 33);
  padding-top: 3vh;
  padding-bottom: 3vh;
}


/* ===== Modals ===== */
.question-modal {
  overflow: auto;
  height: fit-content;
  max-height: 80vh;
  display: flex;
  margin: 0 auto;
  width: 50vw;
  background-color: var(--question-modal-bg);
  padding: 6vh 5vh;
  padding-bottom: 7.5vh;
  border-radius: 15px;
  text-align: left;
  font-size: 1.3rem;
  border: 1px solid var(--question-modal-border);
  color: var(--question-modal-default-color);
}
.question-modal h1, .settings-tagline {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5vh;
}
.settings-tagline {
  margin-bottom: 2vh;
}
.question {
  color: var(--question-color);
  font-size: 1.2rem;
  font-weight: 500;
}
.settings-form {
  align-items: flex-start;
  width: 90%;
}
.settings-form label {
  font-size: 1.2rem;
  margin: 1vw;
  margin-left: 0;
  margin-top: 1.8vh;
}
.settings-label {
  color: var(--settings-label-color);
  font-weight: 500;
}
.settings-form input {
  box-sizing: border-box;
  background-color: var(--form-bg);
  color: var(--form-input-color);
  border-radius: 6px;
  width: 100%;
  font-size: 1.2rem;
  padding: 10px;
  border: none;
  font-family: 'JetBrains Mono', monospace;
}
.questions-modal-title {
  font-size: 2.8rem;
  color: var(--questions-modal-color);
}
.settings-form {
  margin-bottom: 1vw;
}
.settings-form input:focus {
  outline: none;
}
.update-btn {
  display: block;
  margin: 0 auto;
  border: none;
  margin-top: 3vh;
  padding: 2vh 4vh;
  font-size: 1.1rem;
  background-color: var(--update-btn-bg);
  color: var(--update-btn-color);
  border-radius: 8px;
  font-family: 'JetBrains Mono', monospace;
  cursor: pointer;
}
.question-ans-break {
  word-wrap: break-word;
}
.success-message {
  text-align: center;
}

/* ===== SDKs ===== */
.sdk {
  width: 100%;
  padding-top: 9vh;
}

.sdk-rows {
  width: 70vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.sdk-title {
  font-size: 2.5rem;
  color: var(--sample-title-color);
}
.sdk-tagline {
  margin-bottom: 4vh;
}
.sdk-img {
  margin: 1.5vh;
  width: 6vw;
  height: auto;
  transition: transform .2s;
}
.sdk-img:hover {
  transform: scale(1.2); 
}
.gosdk-size {
  width: 8vw;
}
.nodesdk-size {
  width: 8vw;
}
.kotlin-size {
  width: 5.5vw;
}

.java-size {
  width: 3.3vw;
}
.gosdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--gosdk-shadow));
}
.kotlinsdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--kotlin-shadow));
}
.javasdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--java-shadow));
}
.nodesdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--node-shadow));
}
.pythonsdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--python-shadow));
}
.reactsdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--react-shadow));
}
.websdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--web-shadow));
}
.swiftsdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--swift-shadow));
}
.fluttersdk {
  -webkit-filter: drop-shadow(0px 0px 30px var(--flutter-shadow));
}


/*  ===== Navbar ===== */
.logo-link {
  width: 10vh;
  height: 11vh;
  padding: 2vh;
  padding-left: 0.9vh;
  background-color: #121217;
  border-bottom-right-radius: 100%;
}
.logo {
  margin: 3px;
  width: 7vh;
  height: auto;
  display: flex;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.navbar-menu {
  margin-left: auto;
}
.navbar-menu ul {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-right: 3vh;
}
.navbar-menu li {
  list-style-type: none;
  margin-right: 2.5vh;
  font-weight: 500;
  font-size: 1rem;
}
.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
  color: var(--nav-link);
}
.nav-link:hover {
  color: var(--nav-link-hover);
}
.hamburger {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
  visibility: hidden;
  color: var(--hamburger-color);
}
.navbar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--navbar-btn-bg);
  border-radius: 100px;
  border: 0.09375rem solid var(--navbar-btn-border);
  height: 5.5vh;
  width: 5.5vh;
  box-shadow: 0px 0px 5px rgb(91, 141, 154);
  color: var(--navbar-btn-color);
  cursor: pointer;
}
.question-icon {
  color: var(--question-icon-color);
  border: 0.09375rem solid var(--question-icon-border);
}
.settings-icon {
  color: var(--settings-icon-color);
  border: 0.09375rem solid var(--settings-icon-border);
}
.light-hover:hover {
  color: rgb(88, 222, 255);
}
.nav-space {
  margin-right: 1vh;
}
.slack-icon {
  width: 3vh;
  height: auto;
  margin-left: 11px;
}
.bg-gray {
  background-color: var(--bg-bg);
  border: 2px solid rgb(163, 229, 255);
  color: var(--nav-bggray-link);
  padding-left: 3vh;
  padding-right: 3vh;
}
.navbar-btn:hover {
  box-shadow: 0px 0px 8px var(--pulse-bg);
}
.inner-question-container {
  overflow: scroll;
  height: 100%;
  width: 90%;
}


/*  ===== Gradient Border ===== */
.gradient-border {
  background-image: linear-gradient(#1c1c23,#1c1c23),linear-gradient(180deg,#0081b3,#0ff 47.16%,#6fef2d);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: inset 2px 2px 2px rgba(0,0,0,.8);
  transition-property: color,border-color,box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 0.09375rem solid transparent;
  box-shadow: 0px 0px 5px rgb(91, 141, 154);
}


/* ==== FlowDownload ===== */
.download-btn {
  background: none;
  border: none;
  color: var(--download-btn-text);
  width: 50vh;
  height: 100%;
  background-color: var(--download-btn-bg);
  cursor: pointer;
  margin-bottom: 3vh;
  margin-top: 1vh;
  padding: 1vh;
  border-radius: 8px;
}
.react-select-container, .react-select__value-container {
    font-size: 1.1rem;
    color: var(--select-container-text);
    background-color: var(--select-container-bg);
    outline: none;
    border-radius: 8px;
    width: 50vh;
}
.react-select__value-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.react-select__option.react-select__option--is-selected {
  background-color: var(--select-focus);
  color: var(--select-container-text);
  padding: 2vh;
}
.react-select__input-container {
  color: var(--select-container-text);
}
.react-select__menu.react-select__menu {
  margin-top: 1vh;
  border-radius: 10px;
}
.react-select__menu-list {
  background-color: var(--select-container-list-bg);
  border-radius: 8px;
}
.react-select__option.react-select__option--is-focused {
  background-color: var(--select-focus);
  color: var(--select-container-text);
}
.react-select__indicators {
  background-color: var(--select-down-arrow-bg);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.react-select__option {
  color: #0075a3;
}
.download-des {
  text-align: left;
  padding: 4vh;
  font-size: 0.9rem;
  color: var(--select-container-text);
  background-color: var(--download-des-bg);
  line-height: 1.5em;
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}


/* ===== Loading ===== */
.loading-title {
  font-size: 2rem;
}


/*  ===== Responsive ===== */
@media screen and (max-width: 910px) {
  .hamburger {
    display: block;
    visibility: visible;
  }
  .navbar-menu ul {
    flex-direction: column;
    position: absolute;
    top: 12vh;
    left: 0;
    display: none;
    z-index: 1;
    margin: 0;
    margin-bottom: 3px;
    width: 100%;
  }
  .expanded li {
    display: flex;
    flex-direction: column;
    margin-top: 5px;    
    width: 100%;
    align-items: flex-end;
    position: relative;
    left: 50%;
    animation-name: slideleft;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @keyframes slideleft {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  .nav-space {
    margin-right: 0;
  }
  .nav-link {
    color: var(--nav-link);
    margin-right: 2vh;
    text-align: center;
    background-color: var(--navbar-bg);
    border-radius: 10px;
    padding: 3vh 5vh;
  }
  .navbar-menu.expanded ul {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bg-gray {
    border: none;
    color: var(--nav-bggray-link-dark);
  }
  .bg-gray:hover {
    color: var(--nav-bg-mobile);
  }
  .navbar-btn {
    border-radius: 100px;
    border: none;
    color: var(--navbar-btn-color);
    background-color: var(--navbar-btn-bg);
    border: 2px solid var(--navbar-btn-border);
    width: 7vh;
    height: 7vh;
    margin-right: 2vh;
  }
  .navbar-btn-li {
    width: fit-content;
  }
  .light-hover {
    box-shadow: 0px 0px 5px rgba(0, 124, 200);
  }
  pre { 
    max-width: 90vw;
    height: fit-content;
  }
  .jwt-box {
    margin-top: 2vh;
  }
  .example {
    border-radius: 20px;
    flex-wrap: wrap;
    width: 65vw;
  }
  .copy-icon {
    margin: 0;
    margin-top: 2vh;
  }
  .flow-shown {
    width: 70vw;
  }
  .question-modal {
    font-size: 0.8em;
    width: 70vw;
  }
  .question-modal h1, .settings-tagline {
    margin-bottom: 3vh;
  }
  .download-container { 
    width: 100%;
  }
  .download-des {
    text-align: left;
    width: 70%;
  }
  .download-btn, .react-select-container {
    width: 50vw;
  }
}
